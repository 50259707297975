import React from 'react';
import {BrowserRouter, Route, Link} from 'react-router-dom';
import { AiFillMail, AiFillPhone, AiFillPushpin } from "react-icons/ai";

import datas from "../local-json/datas"
import { Carousel, Button, Footer, Testimonial, Blob, Map } from '../components';


const Home = () => {
  return (
    <>
      <div id='Home' className='bg-main-bg h-100 pt-1'>
        <div className='homeContainer mt-4'>
          <div className='heroHome pl-12 xs:pl-0'>
            <div className='homeLeft'>
              <p className='mainTitle'>Prothésiste ongulaire</p>
              <div className='secondTitle'>
                <p>Belle</p>
                <p>jusqu'au bout des</p>
                <p><span>ongles</span><span> !</span></p>
              </div>
              
              <div className='Buttons horizontal'>
                <button className='button black'><a href='https://www.rdv360.com/instants-de-soie?fbclid=IwAR3SL_6ZlQz5W_hsBfEJoTodcLyMwFtXlmCDHzTVrwDPT1WwnI57h95A10c' target="_blank" aria-label= "Lien externe">Prendre RDV</a></button>
                <button className='button transparent'><Link to="/benefit">Prestation</Link></button>
              </div>

              <div className='contactListingMenu'>
                <ul>
                  <li><span><AiFillPushpin /></span>
                    <div>
                      <span>{datas.address1}</span>
                      <span>{datas.address2}</span>
                    </div>
                  </li>
                  <li><span><AiFillPhone /></span>{datas.number}</li>
                  <li><span><AiFillMail /></span>{datas.mail}</li>
                </ul>
              </div>
            </div>
            <div className='homeRight'>
              <div className='hero-img'>
                <img src={`./img/hands/hand-hero.webp`} alt="hero image femme Manucure rouge"/>
              </div>
            </div>
          </div>

          <div className='aboutHome bg-second-bg pl-12 xs:pl-0'>
            <div className='aboutContent'>
              <div className='aboutTitle'>
                <h2 className='textBlob'>A propos de moi 
                  <Blob />
                </h2>
              </div>
              <div className='aboutSubtitle'>
                <h1>Votre Beauté est Ma Priorité</h1>
              </div>
              <div className='aboutDescription'>
                <h3>Esthéticienne diplômée à Skhole d'Art & formé en prothésie ongulaire. Je sublime vos ongles avec des produits de qualité fabriqués en France de la marque Créa'Ongles Distribution qui assure qualité et respect des normes Européennes.</h3>
              </div>
            </div>
            <div className='aboutSvg svg'>
              <img src='./img/svg/olive-branch.svg' alt="image de branche d'olivier"/>
            </div>
          </div>
          <div className='slider bg-second-bg'>
            <div className='sliderContainer'>
              <div className='sliderSvg svg'>
                <img src="./img/svg/willow-branch.svg" alt="image de plante" />
              </div>
              <Carousel />
            </div>
          </div>
          {/* <div className='testimonial bg-second-bg py-20 pl-12 xs:pl-0'>
              <div className='testimonialContainer'>
                <div className='testimonialSvgLeft svg'>
                  <img src="./img/svg/leaves-on-a-branch-diagonal-shape.svg" alt="image de plante Ongles Manucure" />
                </div>
                <div className='testimonialSvgRight svg'>
                  <img src="./img/svg/leaves-on-a-branch-silhouette.svg" alt="image de plante Ongles Manucure" />
                </div>
                
                <Testimonial />
              </div>
            </div> */}
        </div>
        <div className='footer bg-dark-bg'>
          <Footer datas={datas}/>
        </div>
      </div>
    </>
  )
}

export default Home