import React from 'react'
import GoogleMapReact from 'google-map-react';
import datas from "../local-json/datas"
import { AiFillEnvironment } from "react-icons/ai";


const Map = ({ location, zoomLevel, text }) => {

      const LocationPin = () => (
        <div className="pin">
          <p className="pin-text"> <span><AiFillEnvironment/></span> {text}</p>s
        </div>
      )

  return (
    <div className="google-map" style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
        >
            <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
            />
        </GoogleMapReact>
     </div>
    
  )
}

export default Map