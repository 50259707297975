import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';


import './App.scss';
import { Navbar, Footer, Sidebar} from './components';
import { Benefit, Contact, Home } from './pages'


const App = () => {
  const activeMenu = false;


  return (
    <>
        <BrowserRouter>
                {activeMenu ? (
                     <div className={`bg-main-bg w-full`}>
                         <Sidebar />
                     </div>
                   
                ) : (
                    <div className={`bg-main-bg w-full`}>
                        <Navbar />
                    </div>
                )}
                
                <div>
                    <Routes>
                        {/* Home */}
                        <Route path='/'        element={<Home />}/>

                        {/* Pages */}
                        <Route path='/benefit'     element={<Benefit />}/>
                        <Route path='/contact'     element={<Contact />}/>
                    </Routes>
                </div>
           
        </BrowserRouter>
    </>
  )
}

export default App