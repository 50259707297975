import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Carousel = () => {

    const altText = [];
    const dataImg = [];

    const [nbrOfSlides, setnbrOfSlides] = useState(3.3)

    const isPortableSize = window.innerWidth < 768 && window.innerWidth > 475;
    const isPortableSizeSm = window.innerWidth <= 475;
    const isTabletSize = window.innerWidth >= 768 && window.innerWidth < 992;
    const isLaptopSize = window.innerWidth >= 992;


    useEffect(() => {
        if (isPortableSize) {
            setnbrOfSlides(2)
        } else if (isTabletSize) {
           setnbrOfSlides(2.5)
        } else if (isLaptopSize) {
            setnbrOfSlides(3.5)
        } else if (isPortableSizeSm) {
            setnbrOfSlides(1.5)
        }
    })


    for (let i = 1; i <= 13; i++) {
        altText.push(
            {
                txt: `Manicure faux ongles à motif français ${i} design à motifs bloc de couleur ${i}`,
                nbr: i
            }
        )
    }

    altText.map( info => {
        dataImg.push(
            {
                name : `ongles${info.nbr}`,
                src : `./img/work/ongles${info.nbr}.webp`,
                alt: info.txt,
                nbr : info.nbr
            }
        )
    });

  return (
    <Swiper
        slidesPerView = {nbrOfSlides}
        spaceBetween = {30}
    >
        {dataImg.map( img => {
            if(img.src !== undefined) {
                return (
                    <SwiperSlide key={img.nbr}> 
                        <div className='imgContainer'>
                            <img src={img.src} alt={img.alt} />
                        </div>
                    </SwiperSlide>
                )
            }
        })}
    </Swiper>
  )
}

export default Carousel
