import React from 'react';
import { AiFillMail, AiFillPhone, AiFillPushpin, AiFillInstagram, AiFillHighlight, AiFillFacebook } from "react-icons/ai";

import datas from "../local-json/datas";
import { Map } from  '../components';

const Contact = () => {

  const location = {
    address: '750 Rte du Sayé Haut, 82290 La ville-Dieu-du-Temple',
    lat: 44.0417891,
    lng: 1.223788,
  }

  return (
    <div id='Contact'>
      <div className='contactContainer'>
        <div className='aboutSvg svg'>
          <img src='./img/svg/olive-branch.svg' alt="image de branche d'olivier"/>
        </div>
        <div className='aboutSvg svg'>
          <img src='./img/svg/branch-leaf.svg' alt="image de branche d'olivier"/>
        </div>
        <div className='contactDescription'>
          <div className='contactLocalisation contactCard'>
            <div className='contactTitle'>
              <h2>Lieu</h2>
            </div>
            <div className='contactInfos content'>
              <p><AiFillPushpin />{datas.address1}, {datas.address2}</p>
            </div>
          </div>
          <div className='contactAbout contactCard'>
            <div className='contactTitle'>
              <h2>Me contacter</h2>
            </div>
            <div className='contactInfos content'>
              <p><AiFillHighlight />{datas.name}</p>
              <p><AiFillPhone />{datas.number}</p>
              <p><AiFillMail /> {datas.mail}</p>
            </div>
          </div>
          <div className='contactSocial contactCard'>
            <div className='contactTitle'>
              <h2>Suivez mes nouvelles créations !</h2>
            </div>
            <div className='contactInfos content'>
              <a target="_blan" href="https://www.instagram.com/instants_de_soie/?hl=fr" aria-label= "Lien instagram"><AiFillInstagram />Sur instagram</a>
              <a target="_blank" href="https://www.facebook.com/angela.estheticienne" aria-label= "Lien facebook"><AiFillFacebook />Sur Facebook</a>
            </div>
          </div>
        </div>
        <div className='contactMap'>
          <Map location={location} zoomLevel={15} text={"Instants de Soie"}/>
        </div>
      </div>
    </div>
  )
}

export default Contact