import React, {useState, useEffect} from 'react';

import {BrowserRouter, Route, Link} from 'react-router-dom';

import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {

  const [navBarOpen, setNavBarOpen] = useState(true);
  
  const handleToggle = () => {
    setNavBarOpen(!navBarOpen)
  }

  const Content = ({state}) => (
    <ul className={state}>
      <li><Link to="/benefit">Prestation & Tarifs</Link></li>
      <li><a href='https://www.rdv360.com/instants-de-soie?fbclid=IwAR3SL_6ZlQz5W_hsBfEJoTodcLyMwFtXlmCDHzTVrwDPT1WwnI57h95A10c' target="_blank"  aria-label= "Lien externe">Prendre RDV</a></li>
      <li><Link to="/contact">Contact</Link></li>
    </ul>
  )

  return  (
    <div id='Navbar' className='bg-main-bg'>
      {navBarOpen ? <></> : <div className='overlay'></div> }
      <div className='navbarContainer'>
        <div className='logo'>
          <Link to="/">
            <img src='./img/logo/logo-transparent.webp' alt='logo instants de soie angela carenou estheticienne'/>
          </Link>
        </div>
        <div className='navContent text-sm'>
           <Content />
        </div>
        <div className='sideContent'>
          <div className='menuContent'>
            <button className='menuIcon' onClick={handleToggle}>{navBarOpen ? (<div className='openBtn'><AiOutlineMenu /></div>) : (<div className='cancelBtn'><AiOutlineClose /></div>)}</button>
            <Content state={navBarOpen ? "hide" : "show"}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar