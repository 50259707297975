import React from 'react'

const Check = () => {
  return (
    <span className='checkSvgContainer'>
        <img className='checkSvg' src='./img/svg/check.svg' />
    </span>
  )
}

export default Check