import React from 'react'

const Blob = () => {
  return (
    <span className='blobContainer'>
        <svg viewBox="0 0 800 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg">
            <g transform="translate(188.84912872314453, 4.651557922363281)">
            <path className="blob" d="M408,279Q370,308,367,350Q364,392,319,374.5Q274,357,251,351.5Q228,346,194.5,354Q161,362,148.5,333.5Q136,305,108,277.5Q80,250,111,224Q142,198,141,155Q140,112,184,133.5Q228,155,249.5,155.5Q271,156,334,111Q397,66,383,129.5Q369,193,407.5,221.5Q446,250,408,279Z" fill="#FAF0E4"></path>
            </g>
        </svg>
    </span>
  )
}

export default Blob