import React from 'react'

import { Footer, Blob, Check } from '../components';
import datas from "../local-json/datas"


const Benefit = () => {

  const nailsDatas = [
    {
      hands: [
        {
          desc: "Extension Chablon",
          price: "50€",
          img: "./img/work/ongles9.webp"
        },
        {
          desc: "Replissage",
          price: "38€",
          img: "./img/work/ongles1.webp"
        },
        {
          desc: "Gainage",
          price: "38€",
          img: "./img/work/ongles4.webp"
        },
        {
          desc: "Semi-permanent",
          price: "35€",
          img: "./img/work/ongles1.webp"
        },
        {
          desc: "Dépose",
          price: "10€",
          img: "./img/work/ongles15.webp"
        },
        {
          desc: "Réparation ongle",
          price: "4€",
          img: "./img/work/ongles15.webp"
        }
      ],

      feet: [
        {
          desc: "Semi-permanent",
          price: "35€",
        },
      ]
    }
    
  ];

  const bodyDatas = [
    {
      waxing: [
        {
          desc: "Rehaussement cils",
          price: "40€",
        },
        {
          desc: "Teinture de cils",
          price: "15€",
        },
        {
          desc: "Epilation sourcils",
          price: "8€",
        },
        {
          desc: "Epilation lèvres",
          price: "8€",
        },
      ],

      care: [
        {
          desc: "Rehaussement cils",
          price: "40€",
        },
        {
          desc: "Teinture de cils",
          price: "15€",
        },
        {
          desc: "Epilation sourcils",
          price: "8€",
        },
        {
          desc: "Epilation lèvres",
          price: "8€",
        },
      ]
    }
  ];

  const packagesDatas = [
    {
      handsFeet: [
        {
          desc: "Extension mains + Semi-permanent pieds",
          price: "60€",
        },
        {
          desc: "Replissage mains + Semi-permanent pieds",
          price: "50€",
        },
        {
          desc: "Semi-permanent mains + Semi-permanent pieds",
          price: "45€",
        }
      ],

      waxing: [
        {
          desc: "1/2 Jambes / Maillot / Aisselles",
          price: "26€",
        },
        {
          desc: "Jambes entières / Maillot intégral / Aisselles",
          price: "40€",
        },
      ]
    }
  ]; 

  return (
    <div id='Benefit' className='bg-second-bg pt-1'>
        <div className='benefitContainer pl-12'>
          
          <div className='benefitNailContent content'>
            <div className='benefitNailSvg svg'>
              <img src='./img/svg/branch-leaf.svg'/>
            </div>
            <div className='benefitNailSvg svg'>
              <img src='./img/svg/leaves-branch.svg'/>
            </div>
            <div className='benefitTitle'>
              <h1 className='textBlob'>Coté Ongles <Blob /></h1>
            </div>
            <div className='mainContent'>
              <div className='handsContent effectContent'>
                <div className='benefitSubtitle'>
                  <h2>Mains</h2>
                </div>
                  <div className='benefitCard'>
                    <div className='imgContent'>
                      <img src='./img/benefits/hands.webp'/>
                    </div>
                    <div className='cardContent'>
                      {nailsDatas.map(el => {
                        return el.hands.map((hands, subIndex) => (
                          <div className='cardDesc' key={subIndex}>
                            <span className='check'><Check />{hands.desc} - {hands.price}</span>
                          </div>
                        ))
                      })}
                    </div>
                  </div> 
                </div>

              <div className='feetContent effectContent'>
                <div className='benefitSubtitle'>
                  <h2>Pieds</h2>
                </div>
                <div className='benefitCard right'>
                  <div className='imgContent'>
                    <img src='./img/benefits/feet.webp'/>
                  </div>
                  <div className='cardContent'>
                    {nailsDatas.map(el => {
                      return el.feet.map((feet, subIndex) => (
                        <div className='cardDesc' key={subIndex}>
                          <span className='check'><Check />{feet.desc} - {feet.price}</span>
                        </div>
                      ))
                    })}
                  </div>
                </div> 
              </div>
            </div>

          </div>

          <div className='benefitBodyContent content'>
            <div className='benefitBodySvg svg'>
              <img src='./img/svg/leaves-on-a-branch-diagonal-shape.svg'/>
            </div>
            <div className='benefitTitle'>
              <h1 className='textBlob'>Coté visage <Blob /></h1>
            </div>
            <div className='mainContent left'>
              {/* <div className='waxingContent effectContent'>
                <div className='benefitSubtitle'>
                  <h2>Epilations</h2>
                </div>
                <div className='benefitCard'>
                  <div className='imgContent'>
                    <img src='./img/benefits/waxing.webp'/>
                  </div>
                  <div className='cardContent'>
                    {bodyDatas.map(el => {
                      return el.waxing.map((waxing, subIndex) => (
                        <div className='cardDesc' key={subIndex}>
                          <span className='check'><Check />{waxing.desc} - {waxing.price}</span>
                        </div>
                      ))
                    })}
                  </div>
                </div> 
              </div> */}

              <div className='careContent effectContent'>
                
                  <div className='benefitCard right'>
                    <div className='imgContent'>
                      <img src='./img/benefits/care.webp'/>
                    </div>
                    <div className='cardContent'>
                      {bodyDatas.map(el => {
                        return el.care.map((care, subIndex) => (
                          <div className='cardDesc' key={subIndex}>
                            <span className='check'><Check />{care.desc} - {care.price}</span>
                          </div>
                        ))
                      })}
                    </div>
                  </div> 
              </div>
            </div>

          </div>

          <div className='benefitPackageContent content'>
            <div className='benefitPackageSvg svg'>
              <img src='./img/svg/willow-branch.svg'/>
            </div>
            <div className='benefitTitle'>
              <h1 className='textBlob'>Les forfaits <Blob /></h1>
            </div>
          <div className='mainContent left'>
            <div className='packageHandsFeetContent effectContent'>
                <div className='benefitSubtitle'>
                  <h2>Forfaits mains / pieds</h2>
                </div>
                <div className='benefitCard'>
                  <div className='cardContent'>
                    {packagesDatas.map(el => {
                      return el.handsFeet.map((handsFeet, subIndex) => (
                        <div className='cardDesc' key={subIndex}>
                          <span className='check'><Check />{handsFeet.desc} - {handsFeet.price}</span>
                        </div>
                      ))
                    })}
                  </div>
                </div> 
              </div>

              {/* <div className='packageWaxingContent effectContent'>
                <div className='benefitSubtitle'>
                  <h2>Forfaits épilations</h2>
                </div>
                <div className='benefitCard'>
                  <div className='cardContent'>
                    {packagesDatas.map(el => {
                      return el.waxing.map((waxing, subIndex) => (
                        <div className='cardDesc' key={subIndex}>
                          <span className='check'><Check />{waxing.desc} - {waxing.price}</span>
                        </div>
                      ))
                    })}
                  </div>
                </div> 
              </div> */}
            </div>
            
          </div>
        </div>
        <div className='footer bg-dark-bg'>
          <Footer datas={datas}/>
        </div>
    </div>
  )
}

export default Benefit