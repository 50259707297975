import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { AiFillMail, AiFillPhone, AiFillPushpin, AiFillInstagram, AiFillHighlight, AiFillFacebook } from "react-icons/ai";

import { Map } from  '../components';


const Footer = ({datas}) => {

  const location = {
    address: '750 Rte du Sayé Haut, 82290 La ville-Dieu-du-Temple',
    lat: 44.0417891,
    lng: 1.223788,
  }
  
  return (
    <div className='footerContainer'>
      <div className='brandFooter'>
        <img src="./img/logo/logo-footer.webp" alt="logo instants de soie angela carenou estheticienne Manucure ongles" />
      </div>
      <div className='mapFooter'>
        <Map location={location} zoomLevel={14} text={"Instants de Soie"}/>
      </div>
      <div className='socialFooter'>
        <a target="_blan" href="https://www.instagram.com/instants_de_soie/?hl=fr" aria-label= "Lien instagram"><AiFillInstagram /> </a>
        <a target="_blank" href="https://www.facebook.com/angela.estheticienne" aria-label= "Lien facebook"><AiFillFacebook /> </a>
      </div>
      <div className='contactFooter'>
        <ul>
          <li><span><AiFillHighlight /></span>{datas.name}</li>
          <li><span><AiFillPhone /></span>{datas.number}</li>
          <li><span><AiFillMail /></span>{datas.mail}</li>
        </ul>
      </div>
      <div className='signatureFooter'>
        <div className='signature'>Fait avec ❤️ à Nantes par Quentin</div>
      </div>
    </div>
  )
}

export default Footer